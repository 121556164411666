<template>
  <b-modal
    id="booststrapmodal"
    :title="popupTitle"
    centered
    :visible="visible"
    @hidden="handleModalHidden"
    :no-close-on-backdrop="true"
    :hide-header-close="true"
  >
    <div>
      <waste-collection-order-preview-modal
        ref="WasteCollectionOrderPreviewModal"
      />
      <page-loader :loading="isLoading">
        <div v-if="transport != null">
          <b-row>
            <b-col cols="4">
              <span class="font-weight-bold"> {{ T("Web.Generic.Type") }}</span>
            </b-col>
            <b-col cols="8">
              {{ getTypeText(transport.type) }}
            </b-col>
            <b-col cols="4">
              <span class="font-weight-bold">
                {{ T("Web.Generic.Suppliers.Supplier") }}</span
              >
            </b-col>
            <b-col cols="8">
              {{ transport.supplierName }}
            </b-col>
            <b-col cols="4">
              <span class="font-weight-bold">
                {{ T("Web.Generic.Drivings.Origin", "Origin") }}
              </span>
            </b-col>
            <b-col cols="8">
              {{ transport.primaryOrder_PickupLocationName }}
              {{
                transport.primaryOrder_PickupLocationName !=
                  transport.secondaryOrder_PickupLocationName &&
                transport.secondaryOrder_PickupLocationName != null
                  ? `, ${transport.secondaryOrder_PickupLocationName}`
                  : ""
              }}
            </b-col>
            <b-col cols="4">
              <span class="font-weight-bold">
                {{ T("Web.Generic.Drivings.Destination") }}
              </span>
            </b-col>
            <b-col cols="8">
              {{ transport.primaryOrder_ReceivingFacilityName }}
              {{
                transport.primaryOrder_ReceivingFacilityName !=
                  transport.secondaryOrder_ReceivingFacilityName &&
                transport.secondaryOrder_ReceivingFacilityName != null
                  ? `, ${transport.secondaryOrder_ReceivingFacilityName}`
                  : ""
              }}
            </b-col>
            <template v-if="transport.type == 'Solo'">
              <b-col cols="4">
                <span class="font-weight-bold">
                  {{ T("Web.Generic.Drivings.PosibleDoubleDriving") }}
                </span>
              </b-col>
              <b-col cols="8">
                {{
                  getPossibleDoubleDrivingText(transport.possibleDoubleDriving)
                }}
                <div
                  class="doubleDrivingCandidateList"
                  v-if="transport.possibleDoubleDriving"
                >
                  <br />
                  <div
                    v-for="candidate in transport.doubleDrivingCandidate.filter(
                      (d) => d.id != '00000000-0000-0000-0000-000000000000'
                    )"
                    :key="candidate.id"
                    @click="openOrderPreviewModal(candidate.id)"
                  >
                    <div>
                      <div>{{ candidate.orderItemName }}</div>
                      <div>{{ candidate.orderItemRefId }}</div>
                    </div>
                  </div>
                </div>
              </b-col>
              <template v-if="transport.possibleDoubleDriving">
                <b-col cols="4">
                  <span class="font-weight-bold"> Tekst fra chauffør </span>
                </b-col>
                <b-col cols="8">
                  {{
                    transport.possibleDoubleDrivingRejectMessage != ""
                      ? transport.possibleDoubleDrivingRejectMessage
                      : "--"
                  }}
                </b-col>
              </template>
            </template>
          </b-row>

          <div class="timeline-container">
            <h1>Tidslinje</h1>
            <div class="timeline">
              <div class="event">
                <div class="inner">
                  <div>
                    <span>{{ format_datetime(transport.startDateTime) }}</span>
                  </div>
                  <h3>Transport påbegyndt</h3>
                  <span class="d-flex align-items-center"
                    ><feather-icon icon="MapPinIcon" class="mr-1" />
                    {{ transport.primaryOrder_PickupLocationName }}</span
                  >
                  <hr />
                  <b-row>
                    <b-col cols="4">
                      <span class="font-weight-bold">
                        {{ T("Web.Generic.Drivings.Container") }} 1
                      </span>
                    </b-col>
                    <b-col cols="8">
                      <span class="font-weight-bold">{{
                        transport.primaryOrder_RequisitionId
                      }}</span
                      ><br />
                      <span
                        >{{ transport.primaryOrder_ItemName }} ({{
                          transport.primaryOrder_ItemRef
                        }})</span
                      >
                      <br />
                      <span>{{ transport.primaryOrder_FractionName }} </span>
                      <br />
                    </b-col>
                  </b-row>
                  <template
                    v-if="
                      transport.primaryOrder_PickupLocationId ==
                      transport.secondaryOrder_PickupLocationId
                    "
                  >
                    <br />
                    <b-row>
                      <b-col cols="4">
                        <span class="font-weight-bold">
                          {{ T("Web.Generic.Drivings.Container") }} 2
                        </span>
                      </b-col>
                      <b-col cols="8">
                        <span class="font-weight-bold">{{
                          transport.secondaryOrder_RequisitionId
                        }}</span
                        ><br />
                        <span
                          >{{ transport.secondaryOrder_ItemName }} ({{
                            transport.secondaryOrder_ItemRef
                          }})</span
                        >
                        <br />
                        <span
                          >{{ transport.secondaryOrder_FractionName }}
                        </span>
                        <br />
                      </b-col>
                    </b-row>
                  </template>
                  <hr />
                </div>
                <div class="map">
                  <driving-event-map
                    :lat="transport.latAtStart"
                    :lng="transport.lngAtStart"
                    class="mt-1"
                  />
                </div>
              </div>

              <div
                class="event"
                v-if="
                  transport.secondaryOrder_PickupLocationId !=
                    '00000000-0000-0000-0000-000000000000' &&
                  transport.secondaryOrder_PickupLocationId !=
                    transport.primaryOrder_PickupLocationId
                "
              >
                <div class="inner">
                  <div>
                    <span></span>
                  </div>
                  <h3>Container tilføjet</h3>
                  <span class="d-flex align-items-center"
                    ><feather-icon icon="MapPinIcon" class="mr-1" />
                    {{ transport.secondaryOrder_PickupLocationName }}</span
                  >
                  <hr />
                  <b-row>
                    <b-col cols="4">
                      <span class="font-weight-bold">
                        {{ T("Web.Generic.Drivings.Container") }} 2
                      </span>
                    </b-col>
                    <b-col cols="8">
                      <span class="font-weight-bold">{{
                        transport.secondaryOrder_RequisitionId
                      }}</span
                      ><br />
                      <span
                        >{{ transport.secondaryOrder_ItemName }} ({{
                          transport.secondaryOrder_ItemRef
                        }})</span
                      >
                      <br />
                      <span>{{ transport.secondaryOrder_FractionName }} </span>
                    </b-col>
                  </b-row>
                  <hr />
                </div>
              </div>

              <div class="event">
                <div class="inner">
                  <div>
                    <span>{{ format_datetime(transport.unloadedAt) }}</span>
                  </div>
                  <h3>Container 1 aflæsset</h3>
                  <span class="d-flex align-items-center"
                    ><feather-icon icon="MapPinIcon" class="mr-1" />
                    {{ transport.primaryOrder_ReceivingFacilityName }}</span
                  >
                  <hr />
                  <p>
                    {{ transport.primaryOrder_ItemName }} ({{
                      transport.primaryOrder_ItemRef
                    }})
                  </p>
                  <br />
                  <b-row>
                    <b-col cols="4">
                      <span class="font-weight-bold"> Kg registeret </span>
                    </b-col>
                    <b-col cols="8">
                      <b-form-input
                        size="sm"
                        v-model="transport.primaryOrder_KgCollected"
                        @blur="
                          updateOrderKgWasteCollected(
                            transport.primaryOrder_Id,
                            transport.primaryOrder_KgCollected
                          )
                        "
                      />
                    </b-col>
                  </b-row>
                  <hr />
                </div>
                <div class="map">
                  <driving-event-map
                    :lat="transport.latAtUnload"
                    :lng="transport.lngAtUnload"
                    class="mt-1"
                  />
                </div>
              </div>

              <div
                class="event"
                v-if="
                  transport.secondaryOrder_Id !=
                  '00000000-0000-0000-0000-000000000000'
                "
              >
                <div class="inner">
                  <div>
                    <span>{{
                      format_datetime(transport.secondaryUnloadedAt)
                    }}</span>
                  </div>
                  <h3>Container 2 aflæsset</h3>
                  <span class="d-flex align-items-center"
                    ><feather-icon icon="MapPinIcon" class="mr-1" />
                    {{ transport.secondaryOrder_ReceivingFacilityName }}</span
                  >
                  <hr />
                  <p>
                    {{ transport.secondaryOrder_ItemName }} ({{
                      transport.secondaryOrder_ItemRef
                    }})
                  </p>
                  <br />
                  <b-row>
                    <b-col cols="4">
                      <span class="font-weight-bold"> Kg registeret </span>
                    </b-col>
                    <b-col cols="8">
                      <b-form-input
                        size="sm"
                        v-model="transport.secondaryOrder_KgCollected"
                        @blur="
                          updateOrderKgWasteCollected(
                            transport.secondaryOrder_Id,
                            transport.secondaryOrder_KgCollected
                          )
                        "
                      />
                    </b-col>
                  </b-row>
                  <hr />
                </div>
                <div class="map">
                  <driving-event-map
                    :lat="transport.latAtContainerReturned"
                    :lng="transport.lngAtContainerReturned"
                    class="mt-1"
                  />
                </div>
              </div>

              <div class="event">
                <div class="inner">
                  <div>
                    <span>{{
                      (transport.containerReturnedAt != "0001-01-01T00:00:00+00:00") ? format_datetime(transport.containerReturnedAt) : ""
                    }}</span>
                  </div>
                  <h3>Container 1 leveret tilbage</h3>
                  <hr />
                </div>
                <div class="map">
                  <driving-event-map
                    :lat="transport.latAtContainerReturned"
                    :lng="transport.lngAtContainerReturned"
                    class="mt-1"
                  />
                </div>
              </div>

              <div
                class="event"
                v-if="
                  transport.secondaryOrder_Id !=
                  '00000000-0000-0000-0000-000000000000'
                "
              >
                <div class="inner">
                  <div>
                    <span>{{
                       (transport.containerReturnedAt != "0001-01-01T00:00:00+00:00") ? format_datetime(transport.secondaryContainerReturnedAt) : ""
                    }}</span>
                  </div>
                  <h3>Container 2 leveret tilbage</h3>
                  <hr />
                </div>
                <div class="map">
                  <driving-event-map
                    :lat="transport.latAtSecondaryContainerReturned"
                    :lng="transport.lngAtSecondaryContainerReturned"
                    class="mt-1"
                  />
                </div>
              </div>

              <div class="event">
                <div class="inner">
                  <div>
                    <span>{{ format_datetime(transport.closedAt) }}</span>
                  </div>
                  <h3>Transport afsluttet</h3>
                  <hr />
                </div>
                <div class="map">
                  <driving-event-map
                    :lat="transport.latAtClose"
                    :lng="transport.lngAtClose"
                    class="mt-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </page-loader>
    </div>
    <template #modal-footer>
      <b-button variant="secondary" @click="handleModalHidden">{{
        T("Web.Generic.Close")
      }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  BFormFile,
  BButton,
  BRow,
  BCol,
  BFormInput,
} from "bootstrap-vue";
import DrivingEventMap from "./DrivingEventMap.vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ResourceService from "@/services/base/resource.service";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import WasteCollectionOrderPreviewModal from "@/app/concern-app/ordering/WasteCollectionOrderPreviewModal.vue";

export default {
  components: {
    BModal,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BFormInput,
    DrivingEventMap,
    PageLoader,
    FeatherIcon,
    WasteCollectionOrderPreviewModal,
  },
  data() {
    return {
      transport: null,
      isLoading: true,

      driving: null,
      visible: false,
    };
  },
  computed: {
    popupTitle() {
      let title = this.T("Web.Generic.Drivings.Driving", "Driving");
      if (this.transport != null) title += ` | ${this.transport.transportId}`;
      return title;
    },
  },
  methods: {
    async open(transportId) {
      this.visible = true;
      const service = new ResourceService("concern/orders/drivings");
      this.transport = (await service.get(transportId)).data.data;
      this.isLoading = false;
    },
    openOrderPreviewModal(orderId) {
      this.$refs.WasteCollectionOrderPreviewModal.open(orderId);
    },
    getTypeText(typeCode) {
      switch (typeCode) {
        case "Unknown":
          return "Ukendt";
        case "Solo":
          return "Solo";
        case "Double":
          return "Dobbelt";
        case "DoubleMultipleOrigins":
          return "Dobbelt (2 pladser)";
        case "DoubleMultipleDestinations":
          return "Dobbelt (2 modtageranlæg)";
        case "DoubleMultipleOriginsAndDestinations":
          return "Dobbelt (2 pladser & 2 modtageranlæg)";
      }
    },
    handleModalHidden() {
      this.transport = null;
      this.visible = false;
      this.isLoading = true;
    },
    getPossibleDoubleDrivingText(value) {
      return value
        ? this.T("Web.Generic.YesOption", "Yes")
        : this.T("Web.Generic.NoOption", "No");
    },
    async updateOrderKgWasteCollected(orderId, value) {
      const service = new ResourceService("concern/orders");
      await service.put(`${orderId}/kg-collected`, {
        kgWasteCollected: value,
      });
      this.$bvToast.toast(`Kg opdateret!`, {
        title: this.T("Web.Generic.UpdateSuccessful", "Updated successfull"),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.doubleDrivingCandidateList {
  > div > div {
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    background-color: #f3f2f7;
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 4px;
    &:hover {
      cursor: pointer;
      background-color: #ebe9f1;
    }
    margin-bottom: 10px;
  }
}

.timeline-container {
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
  h1 {
    background: #f3f2f7;
    font-size: 15px;
    padding: 10px 10px;
    border-bottom: 2px solid #ebe9f1;
  }
  margin-top: 20px;
}

.timeline {
  max-height: 400px;
  overflow-y: scroll;
  padding: 20px;
  position: relative;
  margin-left: 0px;
}

.event {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;

  .inner {
    padding: 20px 20px 0px 20px;
  }

  h3,
  p {
    margin: 0;
  }
  h3 {
    font-size: 15px;
  }
}

.date {
  font-size: 14px;
  color: #555;
}
</style>