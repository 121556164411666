<template>
  <div @click="handleClickEvent">
    <div>{{ text }}</div>
    <div>
      <feather-icon
        v-if="content.sortKey == sortKey && content.dir == 'ASC'"
        icon="ChevronUpIcon"
      />
      <feather-icon
        v-if="content.sortKey == sortKey && content.dir == 'DESC'"
        icon="ChevronDownIcon"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["text", "sortKey", "value"],
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    handleInput() {
      this.$emit("input", this.content);
    },
    handleClickEvent() {
      if (this.content.sortKey == this.sortKey) {
        this.content.dir = this.content.dir == "ASC" ? "DESC" : "ASC";
      } else {
        this.content.dir = "DESC";
        this.content.sortKey = this.sortKey;
      }
      this.handleInput();
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  &:hover {
    cursor: pointer;
  }
  display: flex;
  > div {
    &:last-child {
      padding: 0 5px;
      display: flex;
      flex-direction: column;
      svg {
        color: #6e6b7b;
      }
    }
  }
}
</style>