<template>
  <div class="map-container" @mousedown.stop></div>
</template>

<script>
export default {
  props: {
    lng: {
      required: false,
      default: 0,
    },
    lat: {
      required: false,
      default: 0,
    },
  },
  mounted() {
    let myLatlng = new google.maps.LatLng(
      this.lat == 0 ? 56.0471309 : this.lat,
      this.lng == 0 ? 10.1751446 : this.lng
    );
    // Options
    let mapOptions = {
      zoom: this.lat == 0 ? 5.5 : 13,
      center: myLatlng,
      streetViewControl: false,
      fullscreenControl: true,
      mapTypeControl: false,
    };
    // Apply options
    let map = new google.maps.Map(this.$el, mapOptions);
    let self = this;
    new google.maps.Marker({
      position: myLatlng,
      map,
    });
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 100px;
}
</style>
