<template>
  <b-modal
    id="booststrapmodal"
    :title="modalTitle"
    centered
    :visible="visible"
    @hidden="handleModalHidden"
    :no-close-on-backdrop="true"
    :hide-header-close="true"
    size="lg"
  >
    <page-loader :loading="isLoading">
      <b-row class="order-row" ref="PrintArea" v-if="order != null">
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Ordering.RequisitionId", "Requisition id")
            }}</span
          >
        </b-col>
        <b-col cols="12" md="8"> {{ order.requisitionId }} </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Ordering.OrderDate", "Order date") }}
          </span>
        </b-col>
        <b-col cols="12" md="8"> {{ format_datetime(order.orderDate) }} </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Status") }}
          </span>
        </b-col>
        <b-col cols="12" md="8"
          ><b-badge variant="danger" v-if="order.status == 'Canceled'">
            {{ T("Web.Generic.Ordering.Status.Cancled", "Canceled") }}
          </b-badge>
          <b-badge variant="secondary" v-else-if="order.status == 'Created'">
            {{ T("Web.Generic.Ordering.Status.Created", "Created") }}
          </b-badge>
          <b-badge variant="primary" v-else>
            {{ T(`Web.Generic.Ordering.Status.${order.status}`) }}</b-badge
          >
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Suplliers.Supplier") }}</span
          >
        </b-col>
        <b-col
          cols="12"
          md="8"
          class="d-flex justify-content-between align-items-center"
        >
          <span>{{ order.orderSupplier.name }}</span>
        </b-col>
        <b-col cols="12"> <hr /> </b-col>

        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Ordering.Orderer", "Orderer") }}</span
          >
        </b-col>
        <b-col cols="12" md="8">
          {{ order.orderer.concernName }}  <br />
          <small
            ><a :href="`mailto:${order.orderer.email}`"
              >{{ order.orderer.name }}
            </a></small
          >
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Locations.Location") }}</span
          >
        </b-col>
        <b-col cols="12" md="8"> {{ order.pickupLocation.name }} </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold"> {{ T("Web.Generic.Address") }}</span>
        </b-col>
        <b-col cols="12" md="8">
          {{ order.pickupLocation.zip }} {{ order.pickupLocation.city }},
          {{ order.pickupLocation.address }}
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Ordering.RecivingFacility", "Reciving facility")
            }}</span
          >
        </b-col>
        <b-col cols="12" md="8">
          <template v-if="order.recivingfacility">
            {{ order.recivingfacility.name }} <br />{{
              order.recivingfacility.zip
            }}
            {{ order.recivingfacility.city }},
            {{ order.recivingfacility.address }}
          </template>
        </b-col>
        <b-col cols="12"> <hr /> </b-col>

        <b-col cols="12" md="4">
          <span class="font-weight-bold"
            >{{ T("Web.Generic.Ordering.ContainerId", "Container Id") }}
          </span>
        </b-col>

        <b-col cols="12" md="8">
          {{ order.orderItem.refId }} / {{ order.orderItem.receiverRefId }}
          <br />
          <small>
            {{ order.orderItem.type }}
            <span v-if="order.orderItem.size > 0"
              >{{ order.orderItem.size }} {{ order.orderItem.sizeUnit }}</span
            >
          </small>
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Infocards.WasteFraction", "Waste fraction")
            }}</span
          >
        </b-col>
        <b-col cols="12" md="8">
          {{
            order.orderItem.wasteFraction
              ? order.orderItem.wasteFraction.name
              : ""
          }}
          <br />
          <small>{{
            order.orderItem.ewcCode ? order.orderItem.ewcCode.name : ""
          }}</small>
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Ordering.WantsEmptied", "Wants emptied") }}</span
          >
        </b-col>
        <b-col cols="12" md="8">
          {{
            T("Web.Generic.Ordering.AsFastAsPossible", "As fast as possible")
          }}
          <!--
        <span v-if="order.orderItem.pickupDays.length == 0">Hurtigts muligt</span>
        <weekday-picker :disabled="true" v-model="order.orderItem.pickupDays" />
        -->
        </b-col>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{ T("Web.Generic.Ordering.Comment", "Comment") }}</span
          >
        </b-col>
        <b-col cols="12" md="8" :class="{ 'bg-warning': order.comment }">
          {{ order.comment }}</b-col
        >
        <template v-if="order.status == 'Accepted'">
          <b-col cols="12" md="4">
            <span class="font-weight-bold">{{
              T("Web.Generic.Ordering.KgWasteCollected", "Kg waste collected")
            }}</span>
          </b-col>
          <b-col cols="12" md="8">
            <b-form-input
              type="number"
              v-model="order.kgWasteCollected"
              disabled
            />
          </b-col>
        </template>
        <b-col cols="12" md="4">
          <span class="font-weight-bold">
            {{
              T("Web.Generic.Ordering.AttachedFiles", "Attached files")
            }}</span
          >
        </b-col>

        <b-col cols="12" md="8">
          <span v-if="order.files.lengt == 0">{{
            T("Web.Generic.Ordering.NoFilesAttached", "No files attached")
          }}</span>
          <div v-else>
            <div class="img-list">
              <div v-for="file in order.files" :key="file.id">
                <a
                  :href="apiBaseUrl() + '/images/' + file.path"
                  target="_blank"
                >
                  <img :src="apiBaseUrl() + '/images/' + file.path" />
                </a>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </page-loader>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol, BBadge, BFormInput } from "bootstrap-vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import ResourceService from "@/services/base/resource.service";

export default {
  components: {
    BModal,
    PageLoader,
    BRow,
    BCol,
    BBadge,
    BFormInput,
  },
  data() {
    return {
      order: null,
      visible: false,
      isLoading: true,
    };
  },
  methods: {
    async open(orderId) {
      this.visible = true;
      const service = new ResourceService("concern/orders");
      this.order = (await service.get(orderId)).data.data;
      this.isLoading = false;
    },
    handleModalHidden() {
      this.order = null;
      this.visible = false;
    },
  },
  computed: {
    modalTitle() {
      if (this.order == null)
        return this.T("Web.Generic.Ordering.Order", "Order");
      return `${this.T("Web.Generic.Ordering.Order", "Order")} - ${
        this.order.requisitionId
      }`;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-row {
  font-size: 15px;

  div {
    margin-bottom: 5px;
  }

  .img-list {
    display: flex;
    max-width: 800px;
    flex-wrap: wrap;
    span.icon-container {
      position: absolute;
      top: 2px;
      right: 2px;
      span {
        &.hover-effect:hover {
          background-color: #cf4330;
        }
      }
    }
    div {
      position: relative;
      width: 180px;
      margin: 3px 2px;
      img {
        width: 100%;
        border-radius: 5px;
      }
    }
  }
}
@media (max-width: 767px) {
  .print-btn {
    display: none;
  }
}
</style>