<template>
  <b-dropdown
    :text="T('Web.Generic.Filters', 'Filters')"
    variant="flat-secondary"
    right
  >
    <template #button-content>
      <feather-icon icon="FilterIcon" />
    </template>
    <b-dropdown-form class="inner">
      <div class="d-flex justify-content-between align-items-end">
        <b-dropdown-header id="dropdown-header-label">
          {{ T("Web.DrivingReportingsPage.Filtering.Options.Type", "Type") }}
        </b-dropdown-header>
        <small
          ><a href="#" @click.prevent="selectDeselectAllTypes">{{
            T("Web.Generic.SelectDeselectAll", "Select/Deselect all")
          }}</a></small
        >
      </div>
      <b-form-checkbox-group
        class="option-group"
        id="checkbox-group-type"
        v-model="content.type"
        @change="$emit('input', content)"
      >
        <b-form-checkbox
          v-for="(type, index) in typeOptions"
          :key="index"
          :value="type"
          >{{ type }}</b-form-checkbox
        >
      </b-form-checkbox-group>
      <div class="d-flex justify-content-between align-items-end">
        <b-dropdown-header id="dropdown-header-label">
          {{
            T(
              "Web.DrivingReportingsPage.Filtering.Options.Collector",
              "Collector"
            )
          }}
        </b-dropdown-header>
        <small
          ><a href="#" @click.prevent="selectDeselectAllCollectors">{{
            T("Web.Generic.SelectDeselectAll", "Select/Deselect all")
          }}</a></small
        >
      </div>
      <b-form-checkbox-group
        class="option-group"
        id="checkbox-group-collectors"
        v-model="content.collectors"
        @change="$emit('input', content)"
      >
        <b-form-checkbox
          v-for="(collector, index) in collectorsOptions"
          :key="index"
          :value="collector"
          >{{ collector }}</b-form-checkbox
        >
      </b-form-checkbox-group>

      <div class="d-flex justify-content-between align-items-end">
        <b-dropdown-header id="dropdown-header-label">
          {{
            T(
              "Web.DrivingReportingsPage.Filtering.Options.Location",
              "Location"
            )
          }}
        </b-dropdown-header>
        <small
          ><a href="#" @click.prevent="selectDeselectAllOrigins">{{
            T("Web.Generic.SelectDeselectAll", "Select/Deselect all")
          }}</a></small
        >
      </div>
      <b-form-checkbox-group
        class="option-group"
        id="checkbox-group-origins"
        v-model="content.origins"
        @change="$emit('input', content)"
      >
        <b-form-checkbox
          v-for="(origin, index) in OriginsOptions"
          :key="index"
          :value="origin"
          >{{ origin }}</b-form-checkbox
        >
      </b-form-checkbox-group>

      <div class="d-flex justify-content-between align-items-end">
        <b-dropdown-header id="dropdown-header-label">
          {{
            T(
              "Web.DrivingReportingsPage.Filtering.Options.RecivingFacility",
              "Reciving facility"
            )
          }}
        </b-dropdown-header>
        <small
          ><a href="#" @click.prevent="selectDeselectAllDestinations">{{
            T("Web.Generic.SelectDeselectAll", "Select/Deselect all")
          }}</a></small
        >
      </div>
      <b-form-checkbox-group
        class="option-group"
        id="checkbox-group-destinations"
        v-model="content.destinations"
        @change="$emit('input', content)"
      >
        <b-form-checkbox
          v-for="(destination, index) in DestinationsOptions"
          :key="index"
          :value="destination"
          >{{ destination }}</b-form-checkbox
        >
      </b-form-checkbox-group>

      <div class="d-flex justify-content-between align-items-end">
        <b-dropdown-header id="dropdown-header-label">
          {{
            T(
              "Web.DrivingReportingsPage.Filtering.Options.Container",
              "Container"
            )
          }}
        </b-dropdown-header>
        <small
          ><a href="#" @click.prevent="selectDeselectAllContainers">{{
            T("Web.Generic.SelectDeselectAll", "Select/Deselect all")
          }}</a></small
        >
      </div>
      <b-form-checkbox-group
        class="option-group"
        id="checkbox-group-destinations"
        v-model="content.containers"
        @change="$emit('input', content)"
      >
        <b-form-checkbox
          v-for="(container, index) in containersOptions"
          :key="index"
          :value="container"
          >{{ container }}</b-form-checkbox
        >
      </b-form-checkbox-group>

      <div class="d-flex justify-content-between align-items-end">
        <b-dropdown-header id="dropdown-header-label">
          {{
            T(
              "Web.DrivingReportingsPage.Filtering.Options.PossibleDoubleDriving",
              "Is possible double driving"
            )
          }}
        </b-dropdown-header>
        <small
          ><a
            href="#"
            @click.prevent="selectDeselectAllDoubleDrivingCandidat"
            >{{ T("Web.Generic.SelectDeselectAll", "Select/Deselect all") }}</a
          ></small
        >
      </div>
      <b-form-checkbox-group
        class="option-group"
        id="checkbox-group-destinations"
        v-model="content.doubleDrivingCandidates"
        @change="$emit('input', content)"
      >
        <b-form-checkbox
          v-for="(option, index) in doubleDrivingCandidatOptions"
          :key="index"
          :value="option"
          >{{ option }}</b-form-checkbox
        >
      </b-form-checkbox-group>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownForm,
  BDropdownHeader,
  BDropdownGroup,
  BCollapse,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
export default {
  props: {
    value: {
      required: true,
    },
    typeOptions: {
      required: true,
    },
    collectorsOptions: {
      required: true,
    },
    OriginsOptions: {
      required: true,
    },
    DestinationsOptions: {
      required: true,
    },
    containersOptions: {
      required: true,
    },
    doubleDrivingCandidatOptions: {
      required: true,
    },
  },
  components: {
    BDropdown,
    BDropdownForm,
    BDropdownHeader,
    BDropdownGroup,
    BCollapse,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    selectDeselectAllTypes() {
      if (this.content.type.length != this.typeOptions.length) {
        this.content.type = this.typeOptions;
      } else {
        this.content.type = [];
      }
      this.$emit("input", this.content);
    },
    selectDeselectAllCollectors() {
      if (this.content.collectors.length != this.collectorsOptions.length) {
        this.content.collectors = this.collectorsOptions;
      } else {
        this.content.collectors = [];
      }
      this.$emit("input", this.content);
    },
    selectDeselectAllOrigins() {
      if (this.content.origins.length != this.OriginsOptions.length) {
        this.content.origins = this.OriginsOptions;
      } else {
        this.content.origins = [];
      }
      this.$emit("input", this.content);
    },
    selectDeselectAllDestinations() {
      if (this.content.destinations.length != this.DestinationsOptions.length) {
        this.content.destinations = this.DestinationsOptions;
      } else {
        this.content.destinations = [];
      }
      this.$emit("input", this.content);
    },
    selectDeselectAllContainers() {
      if (this.content.containers.length != this.containersOptions.length) {
        this.content.containers = this.containersOptions;
      } else {
        this.content.containers = [];
      }
      this.$emit("input", this.content);
    },
    selectDeselectAllDoubleDrivingCandidat() {
      if (
        this.content.doubleDrivingCandidates.length !=
        this.doubleDrivingCandidatOptions.length
      ) {
        this.content.doubleDrivingCandidates =
          this.doubleDrivingCandidatOptions;
      } else {
        this.content.doubleDrivingCandidates = [];
      }
      this.$emit("input", this.content);
    },
  },
};
</script>


<style lang="scss" scoped>
.dropdown-header {
  padding: 0.5rem 0;
}
.inner {
  max-height: 400px;
  overflow: auto;
}
.b-dropdown-form {
  min-width: 280px;
}
.option-group {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid #ebe9f1;
  }
}
</style>