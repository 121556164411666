<template>
  <div class="pb-2">
    <driving-details-modal ref="DrivingDetailsModal" />
    <waste-collection-order-preview-modal
      ref="WasteCollectionOrderPreviewModal"
    />
    <b-card no-body>
      <div class="p-2 d-flex justify-content-between">
        <div class="d-flex justify-content-start">
          <div class="mr-2">
            <label for="example-datepicker">{{
              T("Web.Generic.PeriodFrom", "Period from")
            }}</label>
            <b-form-datepicker
              v-model="fromDate"
              placeholder="YYYY-MM-DD"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              @input="handleTimeSpanChanged"
            />
          </div>
          <div>
            <label for="example-datepicker">{{
              T("Web.Generic.PeriodTo", "Period to")
            }}</label>
            <b-form-datepicker
              v-model="toDate"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              @input="handleTimeSpanChanged"
            />
          </div>
        </div>
        <div class="d-flex">
          <b-form-input
            class="search-input mr-1"
            :placeholder="T('Web.Generic.Search')"
            v-model="searchTerm"
          />
          <div>
            <driving-reporting-filter-drop-down
              v-if="tableFilters != null"
              v-model="tableFilters"
              :type-options="typeFilterOptions"
              :collectors-options="collectorsFilterOptions"
              :origins-options="originFilterOptions"
              :destinations-options="destinationFilterOptions"
              :containers-options="containerFilterOptions"
              :double-driving-candidat-options="
                doubleDrivingCandidateFilterOptions
              "
            />
          </div>
          <div>
            <b-button class="ml-1" @click="exportData">{{
              T("Web.Generic.Export", "Export")
            }}</b-button>
          </div>
        </div>
      </div>
      <page-loader :loading="isLoading">
        <div class="table-con">
          <b-table-simple small>
            <b-thead>
              <b-tr>
                <b-th>Transport id</b-th>
                <b-th
                  ><sortable-column-text
                    text="Transportør"
                    sortKey="supplierName"
                    v-model="activeSortItem"
                /></b-th>
                <b-th
                  ><sortable-column-text
                    text="Type"
                    sortKey="type"
                    v-model="activeSortItem"
                /></b-th>
                <b-th>
                  <sortable-column-text
                    text="Mulig dobbeltkørsel"
                    sortKey="possibleDoubleDriving"
                    v-model="activeSortItem"
                  />
                </b-th>
                <b-th>
                  <sortable-column-text
                    text="Påbegyndt"
                    sortKey="startTime"
                    v-model="activeSortItem"
                  />
                </b-th>
                <b-th>
                  <sortable-column-text
                    text="Afsluttet"
                    sortKey="closedAt"
                    v-model="activeSortItem"
                  />
                </b-th>
                <b-th> Rekvisitionsnummer </b-th>
                <b-th>
                  <sortable-column-text
                    text="Bestillingstidspunkt"
                    sortKey="orderingTime"
                    v-model="activeSortItem"
                  />
                </b-th>
                <b-th>
                  <sortable-column-text
                    text="Fraktion"
                    sortKey="fraction"
                    v-model="activeSortItem"
                  />
                </b-th>
                <b-th>
                  <sortable-column-text
                    text="Kg kørt"
                    sortKey="kgCollected"
                    v-model="activeSortItem"
                  />
                  {{ totalKgSum | toCurrency }}
                </b-th>
                <b-th>
                  <sortable-column-text
                    text="Afhentingssted"
                    sortKey="PickupLocation"
                    v-model="activeSortItem"
                  />
                </b-th>
                <b-th>
                  <sortable-column-text
                    text="Modtageranlæg"
                    sortKey="RecivingFacility"
                    v-model="activeSortItem"
                  />
                </b-th>
                <b-th>
                  <sortable-column-text
                    text="Price"
                    sortKey="price"
                    v-model="activeSortItem"
                /></b-th>
                <b-th>
                  <sortable-column-text
                    text="Tillægspris"
                    sortKey="addonPrice"
                    v-model="activeSortItem"
                  />
                </b-th>
                <b-th>
                  <sortable-column-text
                    text="Total pris"
                    sortKey="totalPrice"
                    v-model="activeSortItem"
                  />
                  {{ totalPriceSum | toCurrency }}
                </b-th>
                <b-th>Faktureret i</b-th>
                <b-th></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="(transport, index) in filteredTransports">
                <b-tr
                  :key="`${transport.id}_1`"
                  :class="{
                    'light-gray': index % 2 == 0,
                  }"
                >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2">{{
                    transport.transportId
                  }}</b-td>
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2">{{
                    transport.supplierName
                  }}</b-td>
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2"
                    ><small>{{ getTypeText(transport.type) }}</small></b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2"
                    ><small v-if="transport.type == 'Solo'">{{
                      getPossibleDoubleDrivingText(
                        transport.possibleDoubleDriving
                      )
                    }}</small></b-td
                  >

                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2"
                    ><small>{{
                      format_datetime(transport.startTime)
                    }}</small></b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2"
                    ><small>{{
                      format_datetime(transport.closedAt)
                    }}</small></b-td
                  >
                  <b-td
                    ><small
                      ><a
                        href="#"
                        @click="
                          openOrderPreviewModal(transport.primaryOrder_Id)
                        "
                      >
                        {{ transport.primaryOrder_RequisitionId }}</a
                      ></small
                    ></b-td
                  >
                  <b-td
                    ><small>{{
                      format_datetime(transport.primaryOrder_OrderTime)
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.primaryOrder_FractionName
                    }}</small></b-td
                  >
                  <b-td>
                    <small>{{
                      transport.primaryOrder_KgCollected | toCurrency
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.primaryOrder_PickupLocationName
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.primaryOrder_ReceivingFacilityName
                    }}</small></b-td
                  >
                  <b-td :rowspan="transport.type == 'Double' ? 2 : 1">
                    {{ transport.primaryOrder_Price | toCurrency }}</b-td
                  >
                  <b-td :rowspan="transport.type == 'Double' ? 2 : 1">
                    {{
                      transport.primaryOrder_AdditionalPrice | toCurrency
                    }}</b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2">
                    {{ transport.totalPrice | toCurrency }}</b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2">
                    <small>
                      <a
                        v-if="transport.invoiceNumber != null"
                        href="#"
                        @click.prevent="
                          downloadInvoiceAccountList(transport.invoiceNumber)
                        "
                        >{{ transport.invoiceNumber }}</a
                      >
                      <span v-else>Ej faktureret</span>
                    </small></b-td
                  >
                  <b-td :rowspan="transport.type == 'Solo' ? 1 : 2">
                    <b-button
                      class="float-right"
                      variant="flat-secondary"
                      size="sm"
                      @click="openDrivingDetailsModal(transport.id)"
                    >
                      {{ T("Web.Generic.ShowDetails", "Show details") }}
                    </b-button>
                  </b-td>
                </b-tr>

                <b-tr
                  :key="`${transport.id}_2`"
                  v-if="transport.type != 'Solo'"
                  :class="{
                    'light-gray': index % 2 == 0,
                  }"
                >
                  <b-td style="padding-left: 0.5rem"
                    ><small>
                      <a
                        href="#"
                        @click="
                          openOrderPreviewModal(transport.secondaryOrder_Id)
                        "
                      >
                        {{ transport.secondaryOrder_RequisitionId }}</a
                      >
                    </small></b-td
                  >
                  <b-td
                    ><small>{{
                      format_datetime(transport.secondaryOrder_OrderTime)
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.secondaryOrder_FractionName
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.secondaryOrder_KgCollected | toCurrency
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.secondaryOrder_PickupLocationName
                    }}</small></b-td
                  >
                  <b-td
                    ><small>{{
                      transport.secondaryOrder_ReceivingFacilityName
                    }}</small></b-td
                  >
                  <b-td
                    v-if="
                      transport.type != 'Solo' && transport.type != 'Double'
                    "
                    >{{ transport.secondaryOrder_Price | toCurrency }}</b-td
                  >
                  <b-td
                    v-if="
                      transport.type != 'Solo' && transport.type != 'Double'
                    "
                    >{{
                      transport.secondaryOrder_AdditionalPrice | toCurrency
                    }}</b-td
                  >
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </div>
      </page-loader>
    </b-card>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import {
  BCard,
  BContainer,
  BFormDatepicker,
  BTable,
  BButton,
  BFormInput,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
} from "bootstrap-vue";
import ResourceService from "@/services/base/resource.service";
import moment from "moment";
import DrivingDetailsModal from "./components/DrivingDetailsModal.vue";
import DrivingReportingFilterDropDown from "./components/DrivingReportingFilterDropDown.vue";
import WasteCollectionOrderPreviewModal from "@/app/concern-app/ordering/WasteCollectionOrderPreviewModal.vue";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import SortableColumnText from "./components/SortableColumnText.vue";

export default {
  components: {
    BFormDatepicker,
    BContainer,
    BCard,
    BTable,
    BButton,
    BFormInput,
    DrivingDetailsModal,
    DrivingReportingFilterDropDown,
    WasteCollectionOrderPreviewModal,
    PageLoader,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    SortableColumnText,
  },
  data() {
    return {
      fromDate: this.getDefaultFromDateValue(),
      toDate: this.getDefaultToDateValue(),
      transports: [],

      data: [],
      searchTerm: "",
      tableFields: [
        {
          key: "transportId",
          label: this.T("Web.Generic.Id", "Id"),
          sortable: true,
        },

        {
          key: "primaryOrder.orderDate",
          label: this.T(
            "Web.Generic.Ordering.OrderingTime",
            "Time of ordering"
          ),
          sortable: true,
        },
        {
          key: "date",
          label: this.T("Web.Generic.Drivings.StartTime", "Start time"),
          sortable: true,
        },
        {
          key: "doubleDriving",
          label: this.T("Web.Generic.Type"),
          sortable: true,
        },

        {
          key: "supplierName",
          label: this.T("Web.Generic.Suppliers.Supplier", "Supplier"),
          sortable: true,
        },
        {
          key: "drivingOrigin",
          label: this.T("Web.Generic.Locations.Location", "Location"),
          sortable: true,
        },
        {
          key: "drivingDestination",
          label: this.T(
            "Web.Generic.Drivings.RecivingFacility",
            "Reciving facility"
          ),
          sortable: true,
        },
        {
          key: "primaryOrder.orderItemName",
          label: `${this.T("Web.Generic.Drivings.Container", "Container")} 1`,
          sortable: true,
        },
        {
          key: "secondaryOrder.orderItemName",
          label: `${this.T("Web.Generic.Drivings.Container", "Container")} 2`,
          sortable: true,
        },

        {
          key: "doubleDrivingCandidate",
          label: this.T(
            "Web.Generic.Drivings.PosibleDoubleDriving",
            "Posible double driving"
          ),
          sortable: true,
        },
        {
          key: "status",
          label: this.T("Web.Generic.Status", "Status"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
      tableFilters: null,
      isLoading: false,
      activeSortItem: {
        sortKey: "closedAt",
        dir: "DESC",
      },
    };
  },
  async created() {
    await this.fetchTransports();
  },
  computed: {
    totalPriceSum() {
      return this.filteredTransports.reduce(
        (prev, curr) => (prev += curr.totalPrice),
        0
      );
    },
    totalKgSum() {
      return this.filteredTransports.reduce(
        (prev, curr) =>
          (prev +=
            curr.secondaryOrder_KgCollected + curr.primaryOrder_KgCollected),
        0
      );
    },
    filteredTransports() {
      let result = this.transports;
      /** Filters */
      console.log(this.tableFilters);
      result = result.filter((i) =>
        this.tableFilters.type.includes(this.getTypeText(i.type))
      );

      result = result.filter((i) =>
        this.tableFilters.collectors.includes(i.supplierName)
      );

      result = result.filter(
        (i) =>
          this.tableFilters.origins.includes(
            i.primaryOrder_PickupLocationName
          ) ||
          this.tableFilters.origins.includes(
            i.secondaryOrder_PickupLocationName
          )
      );

      result = result.filter(
        (i) =>
          this.tableFilters.destinations.includes(
            i.primaryOrder_ReceivingFacilityName
          ) ||
          this.tableFilters.destinations.includes(
            i.secondaryOrder_ReceivingFacilityName
          )
      );
      result = result.filter(
        (i) =>
          this.tableFilters.containers.includes(i.primaryOrder_FractionName) ||
          this.tableFilters.containers.includes(i.secondaryOrder_FractionName)
      );
      result = result.filter((i) =>
        this.tableFilters.doubleDrivingCandidates.includes(
          this.getPossibleDoubleDrivingText(i.possibleDoubleDriving)
        )
      );
      /** Sort */
      switch (this.activeSortItem.sortKey) {
        case "possibleDoubleDriving":
          result.sort((a, b) => {
            var textA =
              a.type != "Solo"
                ? ""
                : this.getPossibleDoubleDrivingText(a.possibleDoubleDriving);
            var textB =
              b.type != "Solo"
                ? ""
                : this.getPossibleDoubleDrivingText(b.possibleDoubleDriving);

            return this.activeSortItem.dir == "DESC"
              ? textB.localeCompare(textA)
              : textA.localeCompare(textB);
          });
          break;
        case "orderingTime":
          result.sort((a, b) => {
            var primaryComparison =
              this.activeSortItem.dir == "DESC"
                ? b.primaryOrder_OrderTime.localeCompare(
                    a.primaryOrder_OrderTime
                  )
                : a.primaryOrder_OrderTime.localeCompare(
                    b.primaryOrder_OrderTime
                  );
            if (primaryComparison != 0 || a.type == "Solo" || b.type == "Solo")
              return primaryComparison;
            return this.activeSortItem.dir == "DESC"
              ? b.secondaryOrder_OrderTime.localeCompare(
                  a.secondaryOrder_OrderTime
                )
              : a.secondaryOrder_OrderTime.localeCompare(
                  b.secondaryOrder_OrderTime
                );
          });
          break;
        case "fraction":
          result.sort((a, b) => {
            var primaryComparison =
              this.activeSortItem.dir == "DESC"
                ? b.primaryOrder_FractionName.localeCompare(
                    a.primaryOrder_FractionName
                  )
                : a.primaryOrder_FractionName.localeCompare(
                    b.primaryOrder_FractionName
                  );
            if (primaryComparison != 0 || a.type == "Solo" || b.type == "Solo")
              return primaryComparison;
            return this.activeSortItem.dir == "DESC"
              ? b.secondaryOrder_FractionName.localeCompare(
                  a.secondaryOrder_FractionName
                )
              : a.secondaryOrder_FractionName.localeCompare(
                  b.secondaryOrder_FractionName
                );
          });
          break;
        case "PickupLocation":
          result.sort((a, b) => {
            var primaryComparison =
              this.activeSortItem.dir == "DESC"
                ? b.primaryOrder_PickupLocationName.localeCompare(
                    a.primaryOrder_PickupLocationName
                  )
                : a.primaryOrder_PickupLocationName.localeCompare(
                    b.primaryOrder_PickupLocationName
                  );
            if (primaryComparison != 0 || a.type == "Solo" || b.type == "Solo")
              return primaryComparison;
            return this.activeSortItem.dir == "DESC"
              ? b.secondaryOrder_PickupLocationName.localeCompare(
                  a.secondaryOrder_PickupLocationName
                )
              : a.secondaryOrder_PickupLocationName.localeCompare(
                  b.secondaryOrder_PickupLocationName
                );
          });
          break;
        case "RecivingFacility":
          result.sort((a, b) => {
            var primaryComparison =
              this.activeSortItem.dir == "DESC"
                ? b.primaryOrder_ReceivingFacilityName.localeCompare(
                    a.primaryOrder_ReceivingFacilityName
                  )
                : a.primaryOrder_ReceivingFacilityName.localeCompare(
                    b.primaryOrder_ReceivingFacilityName
                  );
            if (primaryComparison != 0 || a.type == "Solo" || b.type == "Solo")
              return primaryComparison;
            return this.activeSortItem.dir == "DESC"
              ? b.secondaryOrder_ReceivingFacilityName.localeCompare(
                  a.secondaryOrder_ReceivingFacilityName
                )
              : a.secondaryOrder_ReceivingFacilityName.localeCompare(
                  b.secondaryOrder_ReceivingFacilityName
                );
          });
          break;
        case "kgCollected":
          result.sort((a, b) => {
            let valueA =
              a.primaryOrder_KgCollected + a.secondaryOrder_KgCollected;
            let valueB =
              b.primaryOrder_KgCollected + b.secondaryOrder_KgCollected;
            return this.activeSortItem.dir == "DESC"
              ? valueB - valueA
              : valueA - valueB;
          });
          break;
        case "price":
          result.sort((a, b) => {
            let valueA = a.primaryOrder_Price + a.secondaryOrder_Price;
            let valueB = b.primaryOrder_Price + b.secondaryOrder_Price;
            return this.activeSortItem.dir == "DESC"
              ? valueB - valueA
              : valueA - valueB;
          });
          break;
        case "addonPrice":
          result.sort((a, b) => {
            let valueA =
              a.primaryOrder_AdditionalPrice + a.secondaryOrder_AdditionalPrice;
            let valueB =
              b.primaryOrder_AdditionalPrice + b.secondaryOrder_AdditionalPrice;
            return this.activeSortItem.dir == "DESC"
              ? valueB - valueA
              : valueA - valueB;
          });
          break;
        case "totalPrice":
          return result.sort((a, b) =>
            this.activeSortItem.dir == "DESC"
              ? b.totalPrice - a.totalPrice
              : a.totalPrice - b.totalPrice
          );
          break;
        default:
          result.sort((a, b) =>
            this.activeSortItem.dir == "DESC"
              ? b[this.activeSortItem.sortKey].localeCompare(
                  a[this.activeSortItem.sortKey]
                )
              : a[this.activeSortItem.sortKey].localeCompare(
                  b[this.activeSortItem.sortKey]
                )
          );
          break;
      }
      /** Search */
      if (this.searchTerm == "") return result;
      let lowerSearchTerm = this.searchTerm.toLowerCase();
      return result.filter(
        (d) =>
          d.transportId.toLowerCase().includes(lowerSearchTerm) ||
          d.primaryOrder_RequisitionId
            .toLowerCase()
            .includes(lowerSearchTerm) ||
          (d.type != "Solo" &&
            d.secondaryOrder_RequisitionId
              .toLowerCase()
              .includes(lowerSearchTerm)) ||
          d.primaryOrder_FractionName.toLowerCase().includes(lowerSearchTerm) ||
          d.primaryOrder_PickupLocationName
            .toLowerCase()
            .includes(lowerSearchTerm) || (d.primaryOrder_ReceivingFacilityName &&
          d.primaryOrder_ReceivingFacilityName
            .toLowerCase()
            .includes(lowerSearchTerm)) ||
          (d.type != "Solo" && d.secondaryOrder_FractionName &&
            d.secondaryOrder_FractionName
              .toLowerCase()
              .includes(lowerSearchTerm)) ||
          (d.type != "Solo" && d.secondaryOrder_PickupLocationName &&
            d.secondaryOrder_PickupLocationName
              .toLowerCase()
              .includes(lowerSearchTerm)) ||
          (d.type != "Solo" && d.secondaryOrder_ReceivingFacilityName &&
            d.secondaryOrder_ReceivingFacilityName
              .toLowerCase()
              .includes(lowerSearchTerm))
      );
    },

    typeFilterOptions() {
      return [
        ...new Set(
          this.transports
            .map((i) => this.getTypeText(i.type))
            .sort((a, b) => a.localeCompare(b))
        ),
      ];
    },
    collectorsFilterOptions() {
      return [
        ...new Set(
          this.transports
            .map((i) => i.supplierName)
            .sort((a, b) => a.localeCompare(b))
        ),
      ];
    },
    originFilterOptions() {
      let list = this.transports.map((i) => i.primaryOrder_PickupLocationName);
      list.concat(
        this.transports
          .filter((t) => t.secondaryOrder_PickupLocationName != null)
          .map((i) => i.secondaryOrder_PickupLocationName)
      );
      return [...new Set(list.sort((a, b) => a.localeCompare(b)))];
    },
    destinationFilterOptions() {
      let list = this.transports.map(
        (i) => i.primaryOrder_ReceivingFacilityName
      );
      list.concat(
        this.transports
          .filter((t) => t.secondaryOrder_ReceivingFacilityName != null)
          .map((i) => i.secondaryOrder_ReceivingFacilityName)
      );
      return [
        ...new Set(list.sort((a, b) => (a != null ? a.localeCompare(b) : -1))),
      ];
    },
    containerFilterOptions() {
      let containers = [
        ...this.transports.map((i) => i.primaryOrder_FractionName),
        ...this.transports
          .filter((i) => i.primaryOrder_FractionName != null)
          .map((i) => i.primaryOrder_FractionName),
      ];
      return [...new Set(containers.sort((a, b) => a.localeCompare(b)))];
    },
    doubleDrivingCandidateFilterOptions() {
      return [
        ...new Set(
          this.transports
            .map((i) =>
              this.getPossibleDoubleDrivingText(i.possibleDoubleDriving)
            )
            .sort((a, b) => a.localeCompare(b))
        ),
      ];
    },
  },
  methods: {
    async fetchTransports() {
      this.isLoading = true;
      const service = new ResourceService("concern/orders/drivings");
      this.transports = (
        await service.get("", {
          fromDate: this.fromDate,
          toDate: this.toDate,
        })
      ).data.data;
      this.tableFilters = {
        type: this.typeFilterOptions,
        collectors: this.collectorsFilterOptions,
        origins: this.originFilterOptions,
        destinations: this.destinationFilterOptions,
        doubleDrivingCandidates: this.doubleDrivingCandidateFilterOptions,
        containers: this.containerFilterOptions,
      };
      this.isLoading = false;
    },
    getTypeText(typeCode) {
      switch (typeCode) {
        case "Unknown":
          return "Ukendt";
        case "Solo":
          return "Solo";
        case "Double":
          return "Dobbelt";
        case "DoubleMultipleOrigins":
          return "Dobbelt (2 pladser)";
        case "DoubleMultipleDestinations":
          return "Dobbelt (2 modtageranlæg)";
        case "DoubleMultipleOriginsAndDestinations":
          return "Dobbelt (2 pladser & 2 modtageranlæg)";
      }
    },
    getPossibleDoubleDrivingText(value) {
      return value
        ? this.T("Web.Generic.YesOption", "Yes")
        : this.T("Web.Generic.NoOption", "No");
    },
    getStatus(item) {
      if (item.closedAt != null)
        return this.T("Web.Generic.Drivings.DrivingStatus.Closed", "Closed");
      if (item.deleteDate != null)
        return this.T("Web.Generic.Drivings.DrivingStatus.Cancled", "Cancled");
      if (item.unloadedAt != null)
        return this.T("Web.Generic.Drivings.DrivingStatus.Ongoing", "Ongoing");
      if (item.unloadedAt != null)
        return this.T(
          "Web.Generic.Drivings.DrivingStatus.Unloaded",
          "Unloaded"
        );
      return this.T("Web.Generic.Unknown", "Unknown");
    },

    async downloadInvoiceAccountList(invoiceNumber) {
      const service = new ResourceService(
        `concern/orders/drivings/account-list/${invoiceNumber}`
      );
      const data = (await service.get("")).data.data;

      const fileName = `kontofoderling_${invoiceNumber}.xlsx`;
      const modifiedData = data.map((item) => {
        return {
          "Lokation": item.locationName,
          "Fraktion": item.fractionName,
          "kontonr." : item.internalGroupId,
          "Tons i alt" : item.tonnage.toFixed(3),
          "Antal Dobbeltkørsler" : item.noOfDoubleDrivings.toFixed(1),
          "Antal Solokørsler" : item.noOfSoloDrivings.toFixed(1),
          "Kr i alt" : item.chargeTotal.toFixed(2).toString().replace(/(\d)\.(\d)/g, "$1,$2"),
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(modifiedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, fileName);
    },

    exportData() {
      const fileName = `${this.fromDate}_${this.toDate}.xlsx`;
      const modifiedData = this.filteredTransports.map((item) => {
        return {
          "Transport id": item.transportId,
          Type: this.getTypeText(item.type),
          Påbegyndt: this.format_datetime(item.startTime),
          Afsluttet: this.format_datetime(item.closedAt),
          "Container 1 | Rekvisitionsnummer": item.primaryOrder_RequisitionId,
          "Container 1 | Bestillingstidspunkt": this.format_datetime(
            item.primaryOrder_OrderTime
          ),
          "Container 1 | Fraktion": item.primaryOrder_FractionName,
          "Container 1 | Kg kørt": item.primaryOrder_KgCollected,
          "Container 1 | Afhentingssted": item.primaryOrder_PickupLocationName,
          "Container 1 | Modtageranlæg":
            item.primaryOrder_ReceivingFacilityName,

          "Container 2 | Rekvisitionsnummer":
            item.type != "Solo" ? item.secondaryOrder_RequisitionId : "",
          "Container 2 | Bestillingstidspunkt":
            item.type != "Solo"
              ? this.format_datetime(item.secondaryOrder_OrderTime)
              : "",
          "Container 2 | Fraktion":
            item.type != "Solo" ? item.secondaryOrder_FractionName : "",
          "Container 2 | Kg kørt":
            item.type != "Solo" ? item.secondaryOrder_KgCollected : "",
          "Container 2 | Afhentingssted":
            item.type != "Solo" ? item.secondaryOrder_PickupLocationName : "",
          "Container 2 | Modtageranlæg":
            item.type != "Solo"
              ? item.secondaryOrder_ReceivingFacilityName
              : "",
          "Total pris": item.totalPrice.toFixed(2),
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(modifiedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, fileName);
    },
    openDrivingDetailsModal(drivingId) {
      this.$refs.DrivingDetailsModal.open(drivingId);
    },
    async updateOrderKgWasteCollected(orderId, value) {
      const service = new ResourceService("concern/orders");
      await service.put(`${order.id}/kg-collected`, {
        kgWasteCollected: order.kgWasteCollected,
      });
      this.$bvToast.toast(`Kg opdateret på ${order.requisitionId} `, {
        title: this.T("Web.Generic.UpdateSuccessful", "Updated successfull"),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    handleTimeSpanChanged() {
      localStorage.setItem("Rz_TransportReportingFromDateValue", this.fromDate);
      localStorage.setItem("Rz_TransportReportingToDateValue", this.toDate);

      //this.fetchDrivings();
    },
    getDefaultFromDateValue() {
      var localStorageValue = localStorage.getItem(
        "Rz_TransportReportingFromDateValue"
      );
      return localStorageValue
        ? localStorageValue
        : moment().startOf("month").format("YYYY-MM-DD");
    },
    getDefaultToDateValue() {
      var localStorageValue = localStorage.getItem(
        "Rz_TransportReportingToDateValue"
      );
      return localStorageValue
        ? localStorageValue
        : moment().endOf("day").format("YYYY-MM-DD");
    },
    openOrderPreviewModal(orderId) {
      this.$refs.WasteCollectionOrderPreviewModal.open(orderId);
    },
  },
  watch: {
    fromDate() {
      this.fetchTransports();
    },
    toDate() {
      this.fetchTransports();
    },
  },
};
</script>

<style scoped>
.search-input {
  min-width: 300px;
}
</style>
<style scoped>
.light-gray {
  background: #f8f8f8;
}
.table-con {
  overflow-y: auto;
}
.total-price {
  text-align: right;
  font-size: 23px;
}
</style>